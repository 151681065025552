import React, { useState } from "react";
import "./ContactForm.scss";

const encode = (data) =>
  Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

const ContactForm = () => {
  const [isPosted, setIsPosted] = useState(false);
  const [formContent, setFormContent] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormContent({
      ...formContent,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formContent,
      }),
    })
      .then(() => setIsPosted(true))
      .catch((error) => alert(error));
  };

  return (
    <div className="ContactForm">
      <h3 className="ContactForm__title">Contact</h3>
      {!isPosted ? (
        <React.Fragment>
          <p>
            Vous pouvez nous envoyer un message, mais attention : votre message
            ne sera lu que d'ici quelques jours (privilégiez le téléphone pour
            une prise de contact urgente)
          </p>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <div hidden>
              <label>
                Ne remplissez-pas ici (robot anti-spam):{" "}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </div>
            <div className="ContactForm__field">
              <label className="ContactForm__label" htmlFor={"name"}>
                Votre nom
              </label>
              <input
                className="ContactForm__input"
                placeholder="Entrez votre nom ici"
                type={"text"}
                name={"name"}
                onChange={handleChange}
                id={"name"}
                required={true}
              />
            </div>
            <div className="ContactForm__field">
              <label className="ContactForm__label" htmlFor={"email"}>
                Votre adresse email
              </label>
              <input
                className="ContactForm__input"
                placeholder="Entrez votre adresse email ici"
                type={"email"}
                name={"email"}
                onChange={handleChange}
                id={"email"}
                required={true}
              />
            </div>
            <div className="ContactForm__field">
              <label className="ContactForm__label" htmlFor={"message"}>
                Message
              </label>
              <textarea
                className="ContactForm__textarea"
                name={"message"}
                onChange={handleChange}
                id={"message"}
                required={true}
                placeholder="Entrez votre message ici"
              />
            </div>
            <div className="ContactForm__field">
              <button className="ContactForm__submitButton" type="submit">
                Envoyer
              </button>
            </div>
          </form>
        </React.Fragment>
      ) : (
        <p>Votre message a bien été envoyé, merci !</p>
      )}
    </div>
  );
};

export default ContactForm;
